import { create } from 'zustand'

interface ModalWhatsappReminderStore {
  notShowReminder: boolean
  setNotShowReminder: (payload: boolean) => void
}
export const useWhatsappReminder = create<ModalWhatsappReminderStore>((set) => ({
  notShowReminder: false,
  setNotShowReminder: (payload) => set(() => ({ notShowReminder: payload })),
}))
