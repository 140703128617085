import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Modal,
  Stack,
  Typography,
} from '@mui/material'
import WhatsappReminder from '@/assets/whatsapp-reminder.svg?react'

export const ModalWhatsappReminder = ({ isShow, setIsShow }) => {
  const navigate = useNavigate()
  // const { changeProfileSelected } = useProfileStore()
  const { notShowReminder, setNotShowReminder } = useWhatsappReminder()

  return (
    <Modal
      open={isShow}
      onClose={() => setIsShow(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box
        sx={{
          borderRadius: '10px',
          paddingY: 3,
          paddingX: { xs: 3, sm: 8 },
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          top: '50%',
          left: '50%',
          width: { xs: 380, sm: 550 },
          height: 400,
        }}
      >
        <Box>
          <WhatsappReminder />
        </Box>
        <Typography
          gutterBottom={true}
          sx={{
            fontFamily: 'Inter',
            fontSize: '24px',
            fontWeight: '600',
            lineHeight: '29px',
            letterSpacing: '0em',
            textAlign: 'center',
          }}
        >
          WhatsApp number is required
        </Typography>
        <Stack direction="column">
          <Typography
            gutterBottom={true}
            sx={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '19px',
              letterSpacing: '0em',
              textAlign: 'left',
              marginBottom: 2,
            }}
          >
            Please fill in your phone numbers to receive report features from Indsight.
          </Typography>
          <FormControlLabel
            control={<Checkbox value={notShowReminder} onChange={(e) => setNotShowReminder(e.target.checked)} />}
            label={<Typography fontSize={14}>Don’t show me again</Typography>}
          />
        </Stack>
        <Stack mt={2} direction="row" gap={3}>
          <Button
            onClick={() => {
              setIsShow(false)
            }}
            sx={{ borderRadius: '12px' }}
            disableElevation
            disableRipple
            disableTouchRipple
            size="large"
            variant="outlined"
          >
            Oke
          </Button>
          <Button
            onClick={() => {
              // changeProfileSelected(1)
              navigate('/profile?section=1')
            }}
            disableElevation
            disableRipple
            disableTouchRipple
            size="large"
            variant="contained"
            sx={{ borderRadius: '12px' }}
          >
            Go to settings
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}
