import * as React from 'react'
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Tab from '@mui/material/Tab'
import _ from 'lodash'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import NoDataIcon from '@/assets/no-data-icon.svg?react'
import { useQuery as useReactQuery } from 'react-query'
import SearchIcon from '@mui/icons-material/Search'
import WalletIcon from '@/assets/wallet-icon.svg?react'
import CalendarIcon from '@/assets/calendar-icon.svg?react'
import CoinIcon from '@/assets/coin-icon.svg?react'
import { useNavigate } from 'react-router-dom'
import { useUserWallet } from '@/hooks/User/useUser'
import { getCoinHistory, getSpentHistory } from '@/network/api/api'
import moment from 'moment'
import { formatCurrency } from '@/utils/storage'
import { useGeneralPeriods } from '@/hooks/Entity/useEntity'

const initialParamsTopUp = {
  page: 1,
  size: 10,
  search: '',
  sortBy: 'createdAt',
  sortDirection: 'DESC',
  period: 'All Time',
}
const initialParamsSpend = { page: 1, size: 10, search: '', sortBy: 'date', sortDirection: 'DESC', period: 'All Time' }

export const CoinHistory = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  const navigate = useNavigate()
  const [value, setValue] = React.useState('1')
  const { data: userWallet } = useUserWallet()
  const [params, setParams] = useState(initialParamsTopUp)
  const [paramsSpend, setParamsSpend] = useState(initialParamsSpend)
  const onSetParams = (id, value) => {
    setParams((oldParams) => ({
      ...oldParams,
      [id]: value,
    }))
  }
  const onSetParamsSpend = (id, value) => {
    setParamsSpend((oldParams) => ({
      ...oldParams,
      [id]: value,
    }))
  }
  const { data: dataPeriods } = useGeneralPeriods()
  const { data: dataHistoryWallet, isLoading: isLoadHistoryWalles } = useReactQuery(
    ['HistoryWallet', params],
    () => getCoinHistory(params),
    {
      staleTime: 1000 * 60 * 1,
    },
  )
  const { data: dataSpentHistoryWallet, isLoading: isLoadSpentHistoryWallet } = useReactQuery(
    ['SpentHistoryWallet', paramsSpend],
    () => getSpentHistory(paramsSpend),
    {
      staleTime: 1000 * 60 * 1,
    },
  )
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const handleChangePage = (_event: unknown, newPage: number) => {
    if (newPage < 1) return
    onSetParams('page', newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSetParams('size', parseInt(event.target.value, 10))
    onSetParams('page', 1)
  }
  const handleChangePageSpend = (_event: unknown, newPage: number) => {
    if (newPage < 1) return
    onSetParamsSpend('page', newPage)
  }

  const handleChangeRowsPerPageSpend = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSetParamsSpend('size', parseInt(event.target.value, 10))
    onSetParamsSpend('page', 1)
  }
  const handleRequestSortSpend = (property) => {
    const isAsc = paramsSpend.sortBy === property && paramsSpend.sortDirection === 'ASC'
    onSetParamsSpend('sortBy', property)
    onSetParamsSpend('sortDirection', isAsc ? 'DESC' : 'ASC')
  }
  const handleRequestSort = (property) => {
    const isAsc = params.sortBy === property && params.sortDirection === 'ASC'
    onSetParams('sortBy', property)
    onSetParams('sortDirection', isAsc ? 'DESC' : 'ASC')
  }
  const userWalletData = userWallet?.payload
  const TablePageActions = () => {
    return (
      <Box sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
        <Pagination
          count={Math.ceil(dataHistoryWallet?.payload?.count / params.size)}
          page={params.page}
          onChange={handleChangePage}
        />
      </Box>
    )
  }
  const handleChangeDate = (event: SelectChangeEvent<string>) => {
    // setMetric(event.target.value as string)
    onSetParams('period', event.target.value)
  }
  const handleChangeDateSpend = (event: SelectChangeEvent<string>) => {
    // setMetric(event.target.value as string)
    onSetParamsSpend('period', event.target.value)
  }
  const TablePageActionsSpend = () => {
    return (
      <Box sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
        <Pagination
          count={Math.ceil(dataSpentHistoryWallet?.payload?.count / paramsSpend.size)}
          page={paramsSpend.page}
          onChange={handleChangePageSpend}
        />
      </Box>
    )
  }
  return (
    <Container maxWidth="lg">
      <Box sx={{ p: { xs: 0, sm: 4 } }}>
        <Typography sx={{ fontFamily: 'Inter', fontSize: { xs: 16, sm: 28 }, fontWeight: 600, pt: { xs: 2, sm: 0 } }}>
          Coin Balance & History
        </Typography>
        <Typography sx={{ fontFamily: 'Inter', fontSize: { xs: 10, sm: 14 }, fontWeight: 400, py: 2 }}>
          Stay informed about your coin balance, top up, and view your transaction history all in one place
        </Typography>
        <Divider />
        <Card sx={{ marginTop: 2, width: { xs: '100%', sm: '25%' } }}>
          <Box sx={{ display: 'flex', m: '10px', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ fontSize: { xs: 12, sm: 16 } }}>Total Coins</Typography>
            <WalletIcon />
          </Box>
          <Box sx={{ margin: '10px', display: 'flex', alignItems: 'center', gap: 1 }}>
            <CoinIcon />
            <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: 14 }}>
              {formatCurrency(userWalletData?.balance)} Coins
            </Typography>
          </Box>
          <Box display={'flex'} sx={{ m: 2, justifyContent: 'flex-end' }}>
            <Button
              onClick={() => navigate('/topup')}
              variant="contained"
              sx={{
                p: '0 20px',
                borderRadius: 2,
                color: '#FFFFFF',
                fontFamily: 'Inter',
                fontWeight: 600,
                fontSize: { xs: 12, sm: 16 },
              }}
            >
              Top Up
            </Button>
          </Box>
        </Card>
        <Card sx={{ marginTop: 2 }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                sx={{ width: { xs: '350px', sm: '400px' } }}
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="fullWidth"
                centered={matches ? true : false}
              >
                <Tab label="History Top Up" value="1" />
                <Tab label="History Spend Coin" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <FormControl sx={{ width: 200, mb: 2 }} size="small">
                <Select
                  displayEmpty
                  sx={{ backgroundColor: 'white', borderRadius: 2 }}
                  value={params.period}
                  startAdornment={
                    <InputAdornment position="start">
                      <CalendarIcon />
                    </InputAdornment>
                  }
                  onChange={handleChangeDate}
                >
                  {dataPeriods?.payload?.map((item: any) => (
                    <MenuItem key={item.name} value={item.name}>
                      <Typography pt={{ xs: 0.5, sm: 0 }} fontSize={{ xs: 11, sm: 14 }}>
                        {item.displayName}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TableContainer
                component={Paper}
                sx={{ width: { xs: '100%' } }}
                style={{ maxHeight: '400px', overflow: 'auto', borderRadius: 8 }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                  <TableHead sx={{ backgroundColor: 'rgba(223, 202, 114, 1)' }}>
                    <TableRow
                      sx={{
                        backgroundColor: 'rgba(223, 202,0.2114, 0.2)',
                        // '& .MuiTableCell-head': {
                        //   backgroundColor: 'rgba(223, 202, 114, .2)',
                        // }
                        '& .MuiTableCell-head': {
                          // padding: '6px 16px',
                          paddingY: { xs: 1, sm: 2 },
                          backgroundColor: 'rgba(223, 202, 114, 1)',
                          fontSize: { xs: 10, sm: 16 },
                          lineHeight: 1.5,
                          // backgroundColor: 'blue',
                        },
                      }}
                    >
                      <TableCell>No</TableCell>
                      <TableCell align="center">
                        <TableSortLabel
                          active={params.sortBy === 'coin'}
                          direction={
                            params.sortBy === 'coin'
                              ? params.sortDirection.toLowerCase() === 'desc'
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => handleRequestSort('coin')}
                        >
                          Date
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center">
                        <TableSortLabel
                          active={params.sortBy === 'coin'}
                          direction={
                            params.sortBy === 'coin'
                              ? params.sortDirection.toLowerCase() === 'desc'
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => handleRequestSort('coin')}
                        >
                          Coins
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center">
                        <TableSortLabel
                          active={params.sortBy === 'description'}
                          direction={
                            params.sortBy === 'description'
                              ? params.sortDirection.toLowerCase() === 'desc'
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => handleRequestSort('description')}
                        >
                          Payment Method
                        </TableSortLabel>{' '}
                      </TableCell>
                      <TableCell align="center">
                        <TableSortLabel
                          active={params.sortBy === 'status'}
                          direction={
                            params.sortBy === 'status'
                              ? params.sortDirection.toLowerCase() === 'desc'
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => handleRequestSort('status')}
                        >
                          Status
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center">
                        <TableSortLabel
                          active={params.sortBy === 'total'}
                          direction={
                            params.sortBy === 'total'
                              ? params.sortDirection.toLowerCase() === 'desc'
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => handleRequestSort('total')}
                        >
                          Total
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!_.isEmpty(dataHistoryWallet?.payload?.rows) ? (
                      !isLoadHistoryWalles &&
                      (dataHistoryWallet?.payload?.rows || []).map((row, index) => (
                        <TableRow
                          key={index + 1}
                          sx={{
                            // '&:last-child td, &:last-child th': { border: 0 }
                            '&:last-child td, &:last-child th': { border: 0 },
                            '& .MuiTableCell-body': {
                              fontSize: { xs: 10, sm: 16 },
                              paddingY: { xs: 1, sm: 2 },
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">{moment(row.createdAt).format('DD MMM YYYY : HH:ss')}</TableCell>
                          <TableCell align="center">{formatCurrency(row.coin)}</TableCell>
                          <TableCell align="center">{row.description}</TableCell>
                          <TableCell align="center">{row.status}</TableCell>
                          <TableCell align="center">IDR {formatCurrency(row.total)}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            textAlign="center"
                            sx={{ height: '100%', width: '100%' }}
                          >
                            <Box
                              sx={{ height: { xs: 80, sm: 103 }, width: { xs: 98, sm: 121 } }}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <NoDataIcon />
                            </Box>
                            <Typography fontWeight={500} fontSize={{ xs: 14, sm: 18 }}>
                              There's no data yet
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={dataHistoryWallet?.payload?.count}
                rowsPerPage={params.size}
                page={params.page - 1}
                showFirstButton
                showLastButton
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePageActions}
              />
            </TabPanel>
            <TabPanel value="2">
              <Box
                sx={{
                  display: 'flex',
                  // justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 4,
                  p: 1,
                }}
              >
                <TextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                      width: '100%',
                      // height: 40,
                      height: { xs: 35, md: 40 },
                      backgroundColor: 'white',
                      fontSize: { xs: 12, md: 16 },
                    },
                  }}
                  onChange={(e) => onSetParamsSpend('search', e.target.value)}
                  variant="outlined"
                  placeholder="Search "
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ height: { xs: 15, md: 25 }, width: { xs: 15, md: 25 } }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControl sx={{ width: 200 }} size="small">
                  <Select
                    displayEmpty
                    sx={{ backgroundColor: 'white', borderRadius: 2, ml: 2 }}
                    value={paramsSpend.period}
                    startAdornment={
                      <InputAdornment position="start">
                        <CalendarIcon />
                      </InputAdornment>
                    }
                    onChange={handleChangeDateSpend}
                  >
                    {dataPeriods?.payload?.map((item: any) => (
                      <MenuItem key={item.name} value={item.name}>
                        <Typography pt={{ xs: 0.5, sm: 0 }} fontSize={{ xs: 11, sm: 14 }}>
                          {item.displayName}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <TableContainer
                component={Paper}
                sx={{ width: { xs: '100%' } }}
                style={{ maxHeight: '400px', overflow: 'auto', borderRadius: 8 }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                  <TableHead sx={{ backgroundColor: 'rgba(223, 202, 114, 1)' }}>
                    <TableRow
                      sx={{
                        backgroundColor: 'rgba(223, 202, 114, 1)',
                        // '& .MuiTableCell-head': {
                        //   backgroundColor: 'rgba(223, 202, 114, 1)',
                        // },
                        '& .MuiTableCell-head': {
                          // padding: '6px 16px',
                          paddingY: { xs: 1, sm: 2 },
                          backgroundColor: 'rgba(223, 202, 114, 1)',
                          fontSize: { xs: 10, sm: 16 },
                          lineHeight: 1.5,
                          // backgroundColor: 'blue',
                        },
                      }}
                    >
                      <TableCell>No</TableCell>
                      <TableCell align="center">
                        <TableSortLabel
                          active={paramsSpend.sortBy === 'date'}
                          direction={
                            paramsSpend.sortBy === 'date'
                              ? paramsSpend.sortDirection.toLowerCase() === 'desc'
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => handleRequestSortSpend('date')}
                        >
                          Date
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center">
                        <TableSortLabel
                          active={paramsSpend.sortBy === 'name'}
                          direction={
                            paramsSpend.sortBy === 'name'
                              ? paramsSpend.sortDirection.toLowerCase() === 'desc'
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => handleRequestSortSpend('name')}
                        >
                          Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        <TableSortLabel
                          active={paramsSpend.sortBy === 'category'}
                          direction={
                            paramsSpend.sortBy === 'category'
                              ? paramsSpend.sortDirection.toLowerCase() === 'desc'
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => handleRequestSortSpend('category')}
                        >
                          Category
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        <TableSortLabel
                          active={paramsSpend.sortBy === 'periods'}
                          direction={
                            paramsSpend.sortBy === 'periods'
                              ? paramsSpend.sortDirection.toLowerCase() === 'desc'
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => handleRequestSortSpend('periods')}
                        >
                          Period
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        <TableSortLabel
                          active={paramsSpend.sortBy === 'total'}
                          direction={
                            paramsSpend.sortBy === 'total'
                              ? paramsSpend.sortDirection.toLowerCase() === 'desc'
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => handleRequestSortSpend('total')}
                        >
                          Total
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoadSpentHistoryWallet && <Typography>Loading ...</Typography>}
                    {!_.isEmpty(dataSpentHistoryWallet?.payload?.rows) ? (
                      !isLoadSpentHistoryWallet &&
                      (dataSpentHistoryWallet?.payload?.rows || []).map((row, index) => (
                        <TableRow
                          key={index + 1}
                          sx={{
                            // '&:last-child td, &:last-child th': { border: 0 }
                            '&:last-child td, &:last-child th': { border: 0 },
                            '& .MuiTableCell-body': {
                              fontSize: { xs: 10, sm: 16 },
                              paddingY: { xs: 1, sm: 2 },
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">{moment(row.date).format('DD MMM YYYY')}</TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row.category}</TableCell>
                          <TableCell align="center">{row.periods}</TableCell>
                          <TableCell align="center">{formatCurrency(row.total)}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            textAlign="center"
                            sx={{ height: '100%', width: '100%' }}
                          >
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ height: { xs: 80, sm: 103 }, width: { xs: 98, sm: 121 } }}
                            >
                              <NoDataIcon />
                            </Box>
                            <Typography fontWeight={500} fontSize={{ xs: 14, sm: 18 }}>
                              There's no data yet
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={dataSpentHistoryWallet?.payload?.count}
                rowsPerPage={paramsSpend.size}
                page={paramsSpend.page - 1}
                showFirstButton
                showLastButton
                onPageChange={handleChangePageSpend}
                onRowsPerPageChange={handleChangeRowsPerPageSpend}
                ActionsComponent={TablePageActionsSpend}
              />
            </TabPanel>
          </TabContext>
        </Card>
      </Box>
    </Container>
  )
}
