import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import moment from 'moment'

export default function ModalChangeAlertFrequent({
  open,
  dataEdit,
  setDataEdit,
  handleClose,
  handleConfirm,
  isConfirmLoading,
}) {
  const getUTCHours = (h: number) => {
    const hour = moment().set({ hour: h, minute: 0 }).toISOString().split('T')[1].split(':')[0]
    if (hour.startsWith('0')) {
      return Number(hour.substring(1))
    } else {
      return Number(hour)
    }
  }

  const alertTime = dataEdit.alertTime || '25'
  const timeType = Number(alertTime) === 25 ? 'everyHour' : 'custom'
  const freq = timeType === 'everyHour' ? '' : alertTime.split(',').length
  const time1 = freq ? alertTime.split(',')[0] : ''
  const time2 = freq ? alertTime.split(',')[1] : ''

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          borderRadius: '10px',
          paddingY: 3,
          paddingX: { xs: 3, sm: 6 },
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          top: '50%',
          left: '50%',
          width: { xs: 370, sm: 580 },
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight="500" fontSize="18px">
            Edit Report
          </Typography>
          <CloseIcon onClick={handleClose} cursor="pointer" />
        </Stack>
        <Divider sx={{ borderWidth: '1px', marginY: '8px' }} />
        <FormControl fullWidth sx={{ marginTop: '12px' }}>
          <FormLabel>
            Time(s)
            <span style={{ color: '#c91306' }}>*</span>
          </FormLabel>
          <RadioGroup
            row
            sx={{ gap: 16 }}
            value={timeType}
            onChange={(e) => {
              if (e.target.value === 'everyHour') {
                setDataEdit((prev) => ({ ...prev, alertTime: '25' }))
              } else {
                setDataEdit((prev) => ({ ...prev, alertTime: '17' }))
              }
            }}
          >
            <FormControlLabel value="custom" control={<Radio />} label="Custom" />
            <FormControlLabel value="everyHour" control={<Radio />} label="Every Hour" />
          </RadioGroup>
        </FormControl>
        {freq ? (
          <Stack direction="row" gap={8} px={2}>
            <FormControl>
              <FormLabel>
                Frequent
                <span style={{ color: '#c91306' }}>*</span>
              </FormLabel>
              <Select
                size="small"
                sx={{ width: '60px' }}
                displayEmpty
                value={freq}
                onChange={(e) => {
                  if (e.target.value === '1') {
                    setDataEdit((prev) => {
                      const alertTime = prev.alertTime.split(',')
                      return {
                        ...prev,
                        alertTime: alertTime[0],
                      }
                    })
                  } else {
                    setDataEdit((prev) => {
                      const alertTime = prev.alertTime.split(',')
                      alertTime.push('17')
                      return {
                        ...prev,
                        alertTime: alertTime.join(','),
                      }
                    })
                  }
                }}
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>
                At
                <span style={{ color: '#c91306' }}>*</span>
              </FormLabel>
              <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" alignItems="center" gap={1}>
                <Stack direction={'row'}>
                  <Select
                    size="small"
                    sx={{ width: '100px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    displayEmpty
                    MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                    value={time1}
                    onChange={(e) => {
                      setDataEdit((prev) => {
                        const alertTime = prev.alertTime.split(',')
                        alertTime[0] = e.target.value
                        return {
                          ...prev,
                          alertTime: alertTime.join(','),
                        }
                      })
                    }}
                  >
                    {Array(24)
                      .fill(0)
                      .map((_, idx) => (
                        <MenuItem key={idx} value={getUTCHours(idx)}>
                          {idx}:00
                        </MenuItem>
                      ))}
                  </Select>
                  <Stack
                    border={1}
                    width={48}
                    borderColor="#d3d3d3"
                    color="grey"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ borderTopRightRadius: 4, borderBottomRightRadius: 4, borderLeft: 0 }}
                  >
                    <Typography>WIB</Typography>
                  </Stack>
                </Stack>
                {freq === 2 ? (
                  <>
                    <Box>And</Box>
                    <Stack direction={'row'}>
                      <Select
                        size="small"
                        sx={{ width: '100px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        displayEmpty
                        MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                        value={time2}
                        onChange={(e) => {
                          setDataEdit((prev) => {
                            const alertTime = prev.alertTime.split(',')
                            alertTime[1] = e.target.value
                            return {
                              ...prev,
                              alertTime: alertTime.join(','),
                            }
                          })
                        }}
                      >
                        {Array(24)
                          .fill(0)
                          .map((_, idx) => (
                            <MenuItem key={idx} value={getUTCHours(idx)}>
                              {idx}:00
                            </MenuItem>
                          ))}
                      </Select>
                      <Stack
                        border={1}
                        width={48}
                        borderColor="#d3d3d3"
                        color="grey"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ borderTopRightRadius: 4, borderBottomRightRadius: 4, borderLeft: 0 }}
                      >
                        <Typography>WIB</Typography>
                      </Stack>
                    </Stack>
                  </>
                ) : null}
              </Stack>
            </FormControl>
          </Stack>
        ) : null}
        <Divider sx={{ borderWidth: '1px', mt: 3 }} />
        <Stack gap={2} direction="row" justifyContent="space-between" alignItems="center" marginTop="12px">
          <Stack>
            <Typography fontSize={14} fontWeight={500}>
              Report Details
            </Typography>
            <Typography fontSize={12}>
              {timeType === 'everyHour'
                ? 'The report will be sent every hour'
                : freq === 1
                  ? `The report will be sent once per day, at ${(Number(time1) + 7) % 24}:00 WIB`
                  : `The report will be sent twice per day, at ${(Number(time1) + 7) % 24}:00 WIB and ${(Number(time2) + 7) % 24}:00 WIB`}
            </Typography>
          </Stack>
          <LoadingButton
            loading={isConfirmLoading}
            onClick={handleConfirm}
            sx={{ borderRadius: '12px', height: 40 }}
            disableElevation
            disableRipple
            disableTouchRipple
            size="large"
            variant="contained"
          >
            save
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  )
}
